
import { ErrorLogService, useServiceInjector } from '@/injection'
import { ErrorWithTime } from '@/logging/error-log'
import { useExtendedRouter } from '@/router/extended-router'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const router = useExtendedRouter()

    const inject = useServiceInjector()

    const errorLog = inject(ErrorLogService)

    const copy = (s: string) => {
      navigator.clipboard.writeText(s)
    }

    const errors = errorLog?.errors ?? []

    const errorToString = (e: Error) => {
      if (e instanceof Error) {
        return e.stack ?? `${e.name}: ${e.message} (no stack)`
      }
      else {
        return JSON.stringify(e)
      }
    }

    const errorsAsString = errors.reduce(
      (acc: string, e: ErrorWithTime) => `${acc}\n\n${e.timestamp}: ${errorToString(e)}`,
      "Errors logged:"
    )

    const mailToLink = `mailto:tyleralanmercer%2Bfourcirclessupport%40gmail.com?subject=Errors%20from%20Four%20Circles&body=${encodeURIComponent(errorsAsString)}`

    return {
      errorsAsString,
      errorToString,
      mailToLink,
      errors,
      copy,
      back: () => router.goBackSafely('/', false)
    }
  },
})
